





























import { contextEmployeur } from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import { CreationMotDePasseForm } from '@/components';
import { Employeur } from '@/models';

@Component({
  components: { CreationMotDePasseForm }
})
export default class CreationMotDePasse extends Vue {
  private employeur: Employeur | null = null;

  get employeurData() {
    return contextEmployeur.getEmployeur;
  }
}
