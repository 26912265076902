import { render, staticRenderFns } from "./CreationMotDePasse.vue?vue&type=template&id=2befa800&scoped=true"
import script from "./CreationMotDePasse.vue?vue&type=script&lang=ts"
export * from "./CreationMotDePasse.vue?vue&type=script&lang=ts"
import style0 from "./CreationMotDePasse.vue?vue&type=style&index=0&id=2befa800&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2befa800",
  null
  
)

export default component.exports